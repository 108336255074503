import styled, { css } from "styled-components";
import { Colors, Rem, BP } from "../../commons/Theme";
import { StyledIcon } from "../Icon/style";

export const StyledLateralContainer = styled.div`
  position: fixed;
  cursor: pointer;
  align-items: center;
  border-radius: ${Rem(5)};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
  height: 55px;
  width: ${Rem(80)};
  overflow: hidden;
  ${({ positionY }) => (positionY ? `top: ${positionY};` : "top: 41%;")}
  right: ${Rem(-50)};
  ${({ zindex }) => (zindex ? `z-index: ${zindex};` : "z-index: 12;")}
  background-color:  ${Colors.greenClear};
  transition: transform 2s ease-out 0s;
  
  animation-name: ${({ pulsing }) => (pulsing ? "color;" : `${null};`)}
  animation-duration: 4s;
  animation-iteration-count: infinite;

  @keyframes color {
    0% {
      background-color: ${Colors.greenClear};
    }
    50% {
      background-color: ${Colors.yellow};
    }
    100 {
      background-color: ${Colors.greenClear};
    }
  }

  ${(props) =>
    props.isOpen
    && css`
      transform: translateX(${Rem(-250)});
    `}

  ${StyledIcon} {
    width: ${Rem(35)};
    margin:${Rem(10)};
  }

  display: none;
  @media (${BP.smartphone}) {
    display: inline-block;
  }
`;

export const StyledLateralContainer2 = styled.div`
  position: fixed;
  align-items: center;
  border-radius: ${Rem(5)};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
  width: ${Rem(230)};
  min-height: ${Rem(200)};
  overflow: hidden;
  ${({ positionY }) =>
    positionY ? `top: calc(${positionY} - 1%);` : "top: 40%;"}
  right: ${Rem(-250)};
  ${({ zindex }) => (zindex ? `z-index: ${zindex + 1};` : "z-index: 13;")}
  background-color:  ${Colors.greenClear};
  transition: transform 2s ease-out 0s;

  ${(props) =>
    props.isOpen
    && css`
      transform: translateX(${Rem(-250)});
    `}
`;

export const StyledLateralTextContainer = styled.div`
  padding: ${Rem(10)};
  padding-right: ${Rem(30)};
`;
